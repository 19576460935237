import {
  Box,
  Button,
  Dialog,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import styles from "./index.module.css";
import { Controller, get, useFieldArray, useForm } from "react-hook-form";
import { ChevronDown, IconInfo, IconPlus } from "components/SVG";
import { TrashSimple, WarningRed } from "components/newSVG";
import { useEffect } from "react";

const operators = [
  { id: "contains", name: "Contains" },
  { id: "equalTo", name: "Equal to" },
  { id: "notEqualTo", name: "Not equal to" },
  { id: "isEmpty", name: "is empty" },
  { id: "isNotEmpty", name: "is not empty" },
];

const AddCustomCallOutCome = (props) => {
  const {
    open,
    onClose,
    onSave,
    exisitingCustomOutcomesNames,
    editCustomOutCome,
  } = props;

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: "",
      description: "",
      variableDefinitions: [
        {
          variableType: "{{key}}",
          operator: "contains",
          value: "value",
        },
      ],
      formula: "A",
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "variableDefinitions",
  });

  const handleOnSave = (formData) => {
    onSave(formData);
  };

  const handleAddVariable = () => {
    append({
      variableType: "",
      operator: "",
      value: "",
    });
  };

  useEffect(() => {
    if (!open) {
      reset({
        name: "",
        description: "",
        variableDefinitions: [
          {
            variableType: "{{key}}",
            operator: "contains",
            value: "value",
          },
        ],
        formula: "A",
      });
    }
  }, [open]);

  useEffect(() => {
    if (editCustomOutCome) {
      reset(editCustomOutCome);
    }
  }, [editCustomOutCome]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      PaperProps={{
        style: {
          borderRadius: 20,
          width: 800,
          minHeight: 400,
        },
      }}
    >
      <Box padding="24px" display="flex" flexDirection="column" gap={8}>
        <Typography
          fontSize={26}
          fontWeight={500}
          color="#0f0f0f"
          textAlign="center"
        >
          Add Call Outcome
        </Typography>
        <Box>
          <Typography variant="caption" color="#0f0f0f">
            Call Outcome Name
          </Typography>

          <Controller
            control={control}
            name="name"
            rules={{
              required: "Name is required",
              validate: (value) => {
                return exisitingCustomOutcomesNames
                  .map((innerValues) => innerValues.toLowerCase())
                  .includes(value.toLowerCase())
                  ? "Please use another outcome name, its already used"
                  : true;
              },
            }}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                className={styles.name}
                placeholder="Enter outcome name"
                onChange={onChange}
                value={value}
                error={errors?.name ? true : false}
                helperText={
                  errors?.name && (
                    <Box component="span" display="flex" alignItems="center">
                      <WarningRed style={{ marginRight: 8 }} />
                      <Typography
                        fontSize={10}
                        fontWeight={500}
                        color="error"
                        sx={{ opacity: 0.8 }}
                      >
                        {errors?.name?.message}
                      </Typography>
                    </Box>
                  )
                }
              />
            )}
          />

          <Controller
            control={control}
            name="description"
            rules={{ required: "Description is required" }}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                className={styles.description}
                placeholder="Enter outcome description"
                multiline
                minRows={2}
                onChange={onChange}
                value={value}
                error={errors?.description ? true : false}
                helperText={
                  errors?.description && (
                    <Box component="span" display="flex" alignItems="center">
                      <WarningRed style={{ marginRight: 8 }} />
                      <Typography
                        fontSize={10}
                        fontWeight={500}
                        color="error"
                        sx={{ opacity: 0.8 }}
                      >
                        {errors?.description?.message}
                      </Typography>
                    </Box>
                  )
                }
              />
            )}
          />

          <Box display="flex" alignItems="center" gap={2} mt={4}>
            <Typography
              variant="body2"
              color="#0F0F0F"
              fontWeight={500}
              sx={{ opacity: 0.8 }}
            >
              Variable Definition
            </Typography>

            <IconInfo width={20} height={20} />
          </Box>

          {fields.map(({ id }, index) => (
            <Box my={4} display="flex" gap={4} key={id}>
              <Box flex={1}>
                <Typography
                  variant="caption"
                  color="#0f0f0f"
                  component="p"
                  mb={2}
                >
                  Variable {String.fromCharCode(65 + index)}
                </Typography>
                <Controller
                  name={`variableDefinitions.${index}.variableType`}
                  control={control}
                  rules={{ required: "Variable type is required" }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      onChange={onChange}
                      value={value}
                      error={
                        get(errors, `variableDefinitions.${index}.variableType`)
                          ? true
                          : false
                      }
                      helperText={
                        get(
                          errors,
                          `variableDefinitions.${index}.variableType`
                        ) && (
                          <Box
                            component="span"
                            display="flex"
                            alignItems="center"
                          >
                            <WarningRed style={{ marginRight: 8 }} />
                            <Typography
                              fontSize={10}
                              fontWeight={500}
                              color="error"
                              sx={{ opacity: 0.8 }}
                            >
                              {
                                get(
                                  errors,
                                  `variableDefinitions.${index}.variableType`
                                )?.message
                              }
                            </Typography>
                          </Box>
                        )
                      }
                    />
                  )}
                />
              </Box>
              <Box flex={1}>
                <Typography
                  variant="caption"
                  color="#0f0f0f"
                  component="p"
                  mb={2}
                >
                  Operator
                </Typography>
                <Controller
                  name={`variableDefinitions.${index}.operator`}
                  control={control}
                  rules={{ required: "Operator is required" }}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <Select
                        fullWidth
                        IconComponent={ChevronDown}
                        onChange={onChange}
                        value={value}
                        placeholder="Select workflow"
                        className={styles.dropDown}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: "40vh",
                              width: "20vw",
                            },
                          },
                        }}
                        error={
                          get(errors, `variableDefinitions.${index}.operator`)
                            ? true
                            : false
                        }
                      >
                        {operators.map((operator) => (
                          <MenuItem
                            value={operator.id}
                            sx={{ gap: 3 }}
                            key={operator.id}
                          >
                            <Typography variant="body2">
                              {operator.name}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>

                      {get(errors, `variableDefinitions.${index}.operator`) && (
                        <FormHelperText>
                          <Box
                            component="span"
                            display="flex"
                            alignItems="center"
                          >
                            <WarningRed style={{ marginRight: 8 }} />
                            <Typography
                              fontSize={10}
                              fontWeight={500}
                              color="error"
                              sx={{ opacity: 0.8 }}
                            >
                              {
                                get(
                                  errors,
                                  `variableDefinitions.${index}.operator`
                                )?.message
                              }
                            </Typography>
                          </Box>
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </Box>
              <Box flex={1}>
                <Typography
                  variant="caption"
                  color="#0f0f0f"
                  component="p"
                  mb={2}
                >
                  Value
                </Typography>
                <Controller
                  name={`variableDefinitions.${index}.value`}
                  control={control}
                  // rules={{ required: "Value is required" }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      onChange={onChange}
                      value={value}
                      // error={
                      //   get(errors, `variableDefinitions.${index}.value`)
                      //     ? true
                      //     : false
                      // }
                      // helperText={
                      //   get(errors, `variableDefinitions.${index}.value`) && (
                      //     <Box
                      //       component="span"
                      //       display="flex"
                      //       alignItems="center"
                      //     >
                      //       <WarningRed style={{ marginRight: 8 }} />
                      //       <Typography
                      //         fontSize={10}
                      //         fontWeight={500}
                      //         color="error"
                      //         sx={{ opacity: 0.8 }}
                      //       >
                      //         {
                      //           get(
                      //             errors,
                      //             `variableDefinitions.${index}.value`
                      //           )?.message
                      //         }
                      //       </Typography>
                      //     </Box>
                      //   )
                      // }
                    />
                  )}
                />
              </Box>

              <Box
                sx={{
                  position: "relative",
                  top: 32,
                  visibility: index === 0 ? "hidden" : "visible",
                }}
              >
                <IconButton onClick={() => remove(index)}>
                  <TrashSimple width={16} height={16} />
                </IconButton>
              </Box>
            </Box>
          ))}

          <Button
            sx={{ fontSize: 12, paddingLeft: 0 }}
            variant="outline"
            startIcon={<IconPlus width={16} height={16} />}
            onClick={handleAddVariable}
          >
            Add variable
          </Button>

          <Box mt={4}>
            <Typography variant="body1" fontWeight={500} mb={2}>
              Formula
            </Typography>

            <Controller
              name="formula"
              control={control}
              rules={{ required: "Formula is required" }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  value={value}
                  className={styles.formula}
                  fullWidth
                  multiline
                  minRows={4}
                  placeholder="eg:(A AND B) OR (C AND D)"
                  error={errors?.formula ? true : false}
                  helperText={
                    errors?.formula && (
                      <Box component="span" display="flex" alignItems="center">
                        <WarningRed style={{ marginRight: 8 }} />
                        <Typography
                          fontSize={10}
                          fontWeight={500}
                          color="error"
                          sx={{ opacity: 0.8 }}
                        >
                          {errors?.formula?.message}
                        </Typography>
                      </Box>
                    )
                  }
                />
              )}
            />
          </Box>
        </Box>

        <Box className={styles.buttonContainer}>
          <Button variant="outline" className={styles.button} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="dark"
            className={styles.button}
            onClick={handleSubmit(handleOnSave)}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AddCustomCallOutCome;
