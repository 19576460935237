import { Box, IconButton, Radio, TextField, Typography } from "@mui/material";
import { RadioButton, Trash, TrashSimple, WarningRed } from "components/newSVG";
import { IconInfo } from "components/SVG";
import { Circle } from "react-feather";
import {
  Controller,
  get,
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import { statusTextMapping } from "text-constants/campaign";
import theme from "theme";

const CallOutCome = (props) => {
  const { path, disabled } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { fields, remove } = useFieldArray({
    control,
    name: path,
  });

  return (
    <>
      <Box display="flex" flexDirection="column" gap={4}>
        {fields.map(({ id, callOutcomeType }, index) => (
          <Box
            key={id}
            sx={{
              border: `1px solid ${theme.palette.grey[100]}`,
              padding: 4,
              borderRadius: 3,
              "&: first-of-type": {
                mt: 4,
              },
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="body2" fontWeight={500}>
                {statusTextMapping[callOutcomeType] ?? callOutcomeType}
              </Typography>
              <IconButton onClick={() => remove(index)}>
                <TrashSimple width={16} height={16} />
              </IconButton>
            </Box>

            <Typography
              variant="caption"
              mt={1}
              color={"#1E1E1E"}
              fontWeight={400}
              sx={{ opacity: 0.8, lineHeight: "150%" }}
            >
              Here, users can configure the minimum duration for the first
              redial attempt of phone numbers.
            </Typography>

            <Box display="flex" gap={8} my={4}>
              <Controller
                control={control}
                name={`${path}.${index}.type`}
                render={({ field: { onChange, value } }) => (
                  <>
                    <Box
                      display="flex"
                      gap={1}
                      alignItems="center"
                      component={"label"}
                      sx={{ cursor: "pointer" }}
                    >
                      <Radio
                        disabled={disabled}
                        onClick={() => onChange("interval")}
                        checked={value === "interval"}
                        sx={{ padding: 0 }}
                        icon={<Circle width={20} height={20} />}
                        checkedIcon={<RadioButton width={20} height={20} />}
                      />
                      <Typography
                        variant="caption"
                        fontWeight={400}
                        color="text.primary"
                      >
                        Interval
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      gap={1}
                      alignItems="center"
                      component={"label"}
                      sx={{ cursor: "pointer", opacity: 0.8 }}
                    >
                      <Radio
                        disabled={true}
                        onClick={() => onChange("slot")}
                        checked={value === "slot"}
                        sx={{ padding: 0 }}
                        icon={<Circle width={20} height={20} />}
                        checkedIcon={<RadioButton width={20} height={20} />}
                      />
                      <Typography
                        variant="caption"
                        fontWeight={400}
                        color="text.primary"
                      >
                        Slot
                      </Typography>
                    </Box>
                  </>
                )}
              />
            </Box>

            <Box display="flex" gap={2} mt={4} alignItems="center">
              <Typography variant="caption" fontWeight={500} color="#0f0f0f">
                Repeat Time
              </Typography>
              <IconInfo width={20} height={20} />
            </Box>

            <Box
              display="flex"
              gap={4}
              mt={2}
              position="relative"
              paddingBottom={4}
            >
              <Box
                display="flex"
                alignItems="center"
                gap={2}
                sx={{ width: "12%" }}
              >
                <Controller
                  rules={{ required: "Hours is required" }}
                  control={control}
                  name={`${path}.${index}.repeatTimeHour`}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      disabled={disabled}
                      type="number"
                      sx={{ "& input": { textAlign: "center" } }}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                <Typography variant="body2">Hour</Typography>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                gap={2}
                sx={{ width: "12%" }}
              >
                <Controller
                  rules={{ required: "Minutes is required" }}
                  control={control}
                  name={`${path}.${index}.repeatTimeMinute`}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      disabled={disabled}
                      type="number"
                      sx={{ "& input": { textAlign: "center" } }}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                <Typography variant="body2">Minute</Typography>
              </Box>
            </Box>

            {(get(errors, `${path}[${index}].repeatTimeHour`) ||
              get(errors, `${path}[${index}].repeatTimeMinute`)) && (
              <Box
                component="span"
                display="flex"
                alignItems="center"
                padding="5px 4px"
              >
                <WarningRed style={{ marginRight: 8 }} />
                <Typography
                  fontSize={10}
                  fontWeight={500}
                  color="error"
                  sx={{ opacity: 0.8 }}
                >
                  {get(errors, `${path}[${index}].repeatTimeHour`)
                    ? get(errors, `${path}[${index}].repeatTimeHour`)?.message
                    : get(errors, `${path}[${index}].repeatTimeMinute`)
                        ?.message}
                </Typography>
              </Box>
            )}
          </Box>
        ))}
      </Box>
    </>
  );
};

export default CallOutCome;
