import { Box, Button, IconButton, Typography } from "@mui/material";
import styles from "./index.module.css";
import {
  defaultOutcomes,
  statusColorMapping,
  statusTextMapping,
} from "text-constants/campaign";
import { PencilSimple, TrashSimple } from "components/newSVG";
import { IconInfo, IconPlus } from "components/SVG";
import AddCustomCallOutCome from "../AddCustomCallOutCome";
import { useState } from "react";

const CallOutComeList = (props) => {
  const { customOutcomes, onCustomCallOutcomeUpdate } = props;
  const [customCallOutComeModal, setCustomCallOutComeModal] = useState(false);
  const [editCustomOutCome, setEditCustomOutCome] = useState();

  const handleOnCloseCustomOutCome = () => {
    setCustomCallOutComeModal(false);
    setEditCustomOutCome();
  };

  const handleOnSaveCustomCallOutCome = (newCustomCallOutcome) => {
    if (editCustomOutCome) {
      const editOutComeIndex = customOutcomes.findIndex(
        (oc) => oc.name === editCustomOutCome.name
      );
      let modifiedOutcomes = [...customOutcomes];
      modifiedOutcomes[editOutComeIndex] = newCustomCallOutcome;
      onCustomCallOutcomeUpdate([...modifiedOutcomes]);
    } else {
      onCustomCallOutcomeUpdate([...customOutcomes, newCustomCallOutcome]);
    }
    handleOnCloseCustomOutCome();
  };

  const handleOnDeleteCustomOutCome = (deleteOutComeDetails) => {
    const filteredOutcomes = customOutcomes.filter(
      (oc) => oc.name !== deleteOutComeDetails.name
    );
    onCustomCallOutcomeUpdate(filteredOutcomes);
  };

  const handleOnEditCustomOutCome = (selectedCustomOutCome) => {
    setEditCustomOutCome(selectedCustomOutCome);
    setCustomCallOutComeModal(true);
  };

  return (
    <>
      <Box className={styles.container}>
        <Box>
          <Typography variant="body1" fontWeight={500}>
            Call Outcome Definition
          </Typography>
          <Typography variant="body2" mt={1} color="#505050">
            Define the statuses to identify your calls.
          </Typography>
        </Box>

        <Box>
          <Typography variant="caption" color="#0F0F0F" sx={{ opacity: 0.8 }}>
            System Call Outcomes
          </Typography>

          <Box mt={1} className={styles.defaultOutcomesContainer}>
            {defaultOutcomes.map((defaultOutCome) => (
              <Box className={styles.defaultOutCome} key={defaultOutCome?.id}>
                <Box
                  className={styles.box}
                  sx={{
                    background:
                      statusColorMapping[defaultOutCome?.name].backgroundColor,
                  }}
                >
                  <Typography
                    fontSize={10}
                    fontWeight={500}
                    lineHeight="normal"
                    color={statusColorMapping[defaultOutCome?.name].color}
                  >
                    {statusTextMapping[defaultOutCome?.name] ?? "N/A"}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>

        <Box>
          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="caption" color="#0F0F0F" sx={{ opacity: 0.8 }}>
              Custom Call Outcomes
            </Typography>

            <IconInfo width={20} height={20} />
          </Box>

          {customOutcomes?.length > 0 && (
            <Box className={styles.customOutcomeContainer}>
              {customOutcomes?.map((customOutcome) => (
                <Box className={styles.customOutcome} key={customOutcome?.name}>
                  <Box
                    className={styles.box}
                    sx={{
                      background: statusColorMapping["pending"].backgroundColor,
                    }}
                  >
                    <Typography
                      fontSize={10}
                      fontWeight={500}
                      lineHeight="normal"
                      color={statusColorMapping["pending"].color}
                    >
                      {customOutcome?.name}
                    </Typography>
                  </Box>
                  <Box sx={{ ml: "auto" }}>
                    <IconButton
                      onClick={() => handleOnEditCustomOutCome(customOutcome)}
                    >
                      <PencilSimple width={16} height={16} />
                    </IconButton>
                    <IconButton
                      onClick={() => handleOnDeleteCustomOutCome(customOutcome)}
                    >
                      <TrashSimple width={16} height={16} />
                    </IconButton>
                  </Box>
                </Box>
              ))}
            </Box>
          )}

          <Button
            sx={{ mt: 4, fontSize: 12, paddingLeft: 0 }}
            variant="outline"
            startIcon={<IconPlus width={16} height={16} />}
            onClick={() => setCustomCallOutComeModal(true)}
          >
            Add Custom Call Outcome
          </Button>
        </Box>
      </Box>

      <AddCustomCallOutCome
        open={customCallOutComeModal}
        onClose={handleOnCloseCustomOutCome}
        onSave={handleOnSaveCustomCallOutCome}
        exisitingCustomOutcomesNames={customOutcomes
          ?.map((oc) => oc?.name)
          .filter((name) => name !== editCustomOutCome?.name)}
        editCustomOutCome={editCustomOutCome}
      />
    </>
  );
};

export default CallOutComeList;
