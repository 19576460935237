import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  Grow,
  Tab,
  Tabs,
  Typography,
  Checkbox,
  Slider,
  RadioGroup,
  Radio,
  FormControlLabel,
  Popover,
  InputAdornment,
  TextField,
} from "@mui/material";
import styles from "./index.style";
import FlexBox from "components/FlexBox";
import theme from "theme";
import { DateCalendar } from "@mui/x-date-pickers";
import { Circle } from "react-feather";
import { RadioButton } from "components/newSVG";
import moment from "moment";
import { CalendarToday } from "@mui/icons-material";

const DateSelector = ({ label, value, onChange, minDate, maxDate }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDateChange = (date) => {
    onChange(date);
    handleClose();
  };

  const open = Boolean(anchorEl);

  return (
    <Box mb={2}>
      <Typography variant="caption" mb={1} color="text.primary">
        {label}
      </Typography>
      <TextField
        fullWidth
        value={value ? moment(value).format("DD/MM/YYYY") : ""}
        onClick={handleClick}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CalendarToday
                onClick={handleClick}
                style={{ cursor: "pointer" }}
              />
            </InputAdornment>
          ),
          readOnly: true,
        }}
        placeholder="dd/mm/yyyy"
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ zIndex: 9999 }}
      >
        <DateCalendar
          value={value || null}
          onChange={handleDateChange}
          minDate={minDate}
          maxDate={maxDate}
        />
      </Popover>
    </Box>
  );
};

const FilterModal = ({
  open,
  onClose,
  filters,
  applyFilters,
  fields,
  maxDurationOfCalls,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [tempFilters, setTempFilters] = useState(filters);
  const campaignStatus = [
    "pending",
    "invalidNumber",
    "noAnswered",
    "busy",
    "rejected",
    "networkError",
    "answered",
    "processing",
    "undefinedError",
  ];

  const statusLabels = {
    pending: "Pending",
    invalidNumber: "Invalid Number",
    noAnswered: "No Answer",
    busy: "Busy",
    rejected: "Rejected",
    networkError: "Network Error",
    answered: "Answered",
    processing: "Processing",
    undefinedError: "Undefined Error",
  };
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleFilterChange = (filterName, value) => {
    setTempFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };

  const handleApply = () => {
    const filtersToApply = { ...tempFilters };
    if (filtersToApply.dateFilter === "custom") {
      filtersToApply.dateFilter = "range";
    } else {
      delete filtersToApply.startDate;
      delete filtersToApply.endDate;
    }

    applyFilters(filtersToApply);
    onClose();
  };

  useEffect(() => {
    if (filters.startDate) {
      filters.startDate = moment(filters.startDate);
    }
    if (filters.endDate) {
      filters.endDate = moment(filters.endDate);
    }
    setTempFilters(filters);
  }, [filters]);

  const renderTabContent = () => {
    const field = fields[activeTab];
    switch (field.value) {
      case "status":
        return (
          <>
            {campaignStatus.map((option) => (
              <FlexBox
                component="label"
                key={option}
                columnGap={1}
                sx={{ cursor: "pointer" }}
              >
                <Checkbox
                  checked={tempFilters.status.includes(option)}
                  onChange={() => {
                    const updatedStatus = tempFilters.status.includes(option)
                      ? tempFilters.status.filter((item) => item !== option)
                      : [...tempFilters.status, option];
                    handleFilterChange("status", updatedStatus);
                  }}
                />
                <Typography variant="caption" color="text.primary">
                  {statusLabels[option]}
                </Typography>
              </FlexBox>
            ))}
          </>
        );
      case "redialAttempts":
        return (
          <Box pt={8} sx={{ textAlign: "center" }}>
            <Slider
              value={[
                tempFilters.minRedialAttempts || 0,
                tempFilters.maxRedialAttempts || 10,
              ]}
              onChange={(event, value) => {
                handleFilterChange("minRedialAttempts", value[0]);
                handleFilterChange("maxRedialAttempts", value[1]);
              }}
              valueLabelDisplay="auto"
              min={0}
              max={10}
              marks={[{ value: 0 }, { value: 10 }]}
              sx={{
                width: "80%",
                "& .MuiSlider-rail": { backgroundColor: "#DADADA" },
                "& .MuiSlider-track": { backgroundColor: "#0F0F0F", border: 0 },
                "& .MuiSlider-thumb": {
                  backgroundColor: "#0F0F0F",
                  width: 15,
                  height: 15,
                },
              }}
            />
          </Box>
        );
      case "callDuration":
        return (
          <Box
            pt={8}
            sx={{
              textAlign: "center",
              ...(isNaN(maxDurationOfCalls) && {
                pointerEvents: "none",
                opacity: 0.5,
              }),
            }}
          >
            <Slider
              disabled={isNaN(maxDurationOfCalls)}
              value={[
                tempFilters.minDuration || 0,
                tempFilters.maxDuration || maxDurationOfCalls,
              ]}
              onChange={(event, value) => {
                handleFilterChange("minDuration", value[0]);
                handleFilterChange("maxDuration", value[1]);
              }}
              valueLabelDisplay="auto"
              min={0}
              max={maxDurationOfCalls}
              marks={[{ value: 0 }, { value: maxDurationOfCalls }]}
              sx={{
                width: "80%",
                "& .MuiSlider-rail": { backgroundColor: "#DADADA" },
                "& .MuiSlider-track": { backgroundColor: "#0F0F0F", border: 0 },
                "& .MuiSlider-thumb": {
                  backgroundColor: "#0F0F0F",
                  width: 15,
                  height: 15,
                },
              }}
            />
          </Box>
        );
      case "dateRange":
        return (
          <Box>
            <RadioGroup
              value={tempFilters.dateFilter}
              onChange={(e) => handleFilterChange("dateFilter", e.target.value)}
            >
              <FormControlLabel
                sx={{
                  "& span": {
                    fontSize: 12,
                    fontWeight: 500,
                  },
                }}
                value="today"
                control={
                  <Radio
                    icon={<Circle size={16} />}
                    checkedIcon={<RadioButton size={16} />}
                  />
                }
                label="Today"
              />
              <FormControlLabel
                sx={{
                  "& span": {
                    fontSize: 12,
                    fontWeight: 500,
                  },
                }}
                value="last7days"
                control={
                  <Radio
                    icon={<Circle size={16} />}
                    checkedIcon={<RadioButton size={16} />}
                  />
                }
                label="Last 7 Days"
              />
              <FormControlLabel
                sx={{
                  "& span": {
                    fontSize: 12,
                    fontWeight: 500,
                  },
                }}
                value="thisMonth"
                control={
                  <Radio
                    icon={<Circle size={16} />}
                    checkedIcon={<RadioButton size={16} />}
                  />
                }
                label="This Month"
              />
              <FormControlLabel
                sx={{
                  "& span": {
                    fontSize: 12,
                    fontWeight: 500,
                  },
                }}
                value="custom"
                control={
                  <Radio
                    icon={<Circle size={16} />}
                    checkedIcon={<RadioButton size={16} />}
                  />
                }
                label="Custom"
              />
            </RadioGroup>
            <Grow
              in={tempFilters.dateFilter === "custom"}
              timeout={500}
              unmountOnExit
              mountOnEnter
            >
              <div>
                <DateSelector
                  label="Start Date"
                  value={tempFilters.startDate}
                  onChange={(date) => handleFilterChange("startDate", date)}
                  maxDate={tempFilters.endDate}
                />
                <DateSelector
                  label="End Date"
                  value={tempFilters.endDate}
                  onChange={(date) => handleFilterChange("endDate", date)}
                  minDate={tempFilters.startDate}
                />
              </div>
            </Grow>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      TransitionComponent={Grow}
      PaperProps={{
        sx: styles.dialogPaper,
      }}
    >
      <Box
        sx={{
          p: "16px 24px",
          borderBottom: `1px solid ${theme.palette.borderColor.light}`,
        }}
      >
        <Typography variant="body1" fontWeight={500}>
          Filter
        </Typography>
      </Box>

      <FlexBox alignItems="flex-start">
        <Box
          width={"50%"}
          sx={{ borderRight: `1px solid ${theme.palette.borderColor.light}` }}
        >
          <Tabs
            orientation="vertical"
            value={activeTab}
            onChange={handleTabChange}
            sx={{
              "& .MuiTabs-indicator": {
                display: "none",
              },
            }}
          >
            {fields.map((field, index) => (
              <Tab
                key={field.value}
                label={field.label}
                sx={styles.tab}
                disabled={field.value === "redialAttempts"}
              />
            ))}
          </Tabs>
        </Box>
        <Box width={"50%"} p={2} sx={styles.rightContainer}>
          {renderTabContent()}
        </Box>
      </FlexBox>

      <FlexBox
        sx={{
          p: "16px 24px",
          borderTop: `1px solid ${theme.palette.borderColor.light}`,
          justifyContent: "center",
        }}
      >
        <Button variant="outlined" sx={styles.button} onClick={onClose}>
          Cancel
        </Button>
        <Button variant="dark" sx={styles.button} onClick={handleApply}>
          Apply
        </Button>
      </FlexBox>
    </Dialog>
  );
};

export default FilterModal;
