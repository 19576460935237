import {
  Box,
  Button,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  createFilterOptions,
} from "@mui/material";
import FlexBox from "components/FlexBox";
import EmptyState from "components/EmptyState";
import FundamentoTable from "components/FundamentoTable";
import { useCallback, useEffect, useState } from "react";
import { Loader, X } from "react-feather";
import {
  getCalls,
  getAgentCallsCountOverview as getAgentCallsCountOverviewApi,
  getMarkedByDropdown as getMarkedByDropdownAPI,
} from "services";
import { useURLState } from "custom-hooks/useUrlState";
import FundamentoPopup from "components/FundamentoPopup";
import { useSkillrToast } from "context/toast";
import {
  Info,
  RightArrow,
  SortAscending,
  ThumbDownRedFilled,
  ThumbUpGreenFilled,
} from "components/newSVG";
import theme from "theme";
import { getFormattedTime, getJSONDecoded } from "utils/utils";
import moment from "moment";
import { useUserContext } from "context/user";
import styles from "./index.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import { statusFilter as statusFilterList } from "text-constants/audit";
import getParentCategoriesApi from "services/getParentCategories";
import ExportModal from "pages/Calls/components/ExportModal";
import { IconFilter2 } from "components/SVG";
import FilterModal from "components/FilterModal";

const Audit = (props) => {
  const {
    search: headerSearch,
    date: headerDate,
    setDate: setHeaderDate,
  } = useOutletContext();
  const [modal, setModal] = useState(false);
  const [exportModal, setExportModal] = useState(false);
  const [currentPage, setCurrentPage] = useURLState(1, "page");
  const [callsData, setCallsData] = useState([]);
  const [totalCallsCount, setTotalCallsCount] = useState(0);
  const [validCallsCount, setValidCallsCount] = useState(0);
  const [blankCallsCount, setBlankCallsCount] = useState(0);
  const [junkCallsCount, setJunkCallsCount] = useState(0);
  const [currentCall, setCurrentCall] = useState({});
  const { showSkillrToast } = useSkillrToast();
  const [loading, setLoading] = useState(false);
  const [callTypeTab, setCallTypeTab] = useURLState("All", "callType");
  const [feedback1Filter, setFeedback1Filter] = useURLState([], "feedback1");
  const [feedback2Filter, setFeedback2Filter] = useURLState([], "feedback2");
  const [callTypeFilter, setCallTypeFilter] = useURLState([], "callTypeFilter");
  const [callsCountInfo, setCallsCountInfo] = useState();
  const feedbackList = ["satisfied", "dissatisfied", "unrated"];
  const [paginationCount, setPaginationCount] = useState();
  const [startDateForUrl, setStartDateForUrl] = useURLState("", "startDate");
  const [endDateForUrl, setEndDateForUrl] = useURLState("", "endDate");
  const [statusFilter, setStatusFilter] = useURLState([], "status");
  const [startDateFilter, setStartDateFilter] = useState(() =>
    startDateForUrl
      ? { date: moment(startDateForUrl) }
      : { date: moment().startOf("month") }
  );
  const [endDateFilter, setEndDateFilter] = useState(() =>
    endDateForUrl ? { date: moment(endDateForUrl) } : { date: moment() }
  );
  const [searchBoxValue, setSearchBoxValue] = useState("");
  const { token, buildMode } = useUserContext();
  const tokenInfo = getJSONDecoded(token ?? null);
  // const [showMarkedByPopup, setShowMarkedByPopup] = useState(false);
  const [showMarkedByPopup, setShowMarkedByPopup] = useState({});
  const [markedByDropdownData, setMarkedByDropdownData] = useState([]);
  const [selectedMarkedByID, setSelectedMarkedByID] = useURLState(
    "",
    "markedBy"
  );
  const [parentCategories, setParentCategories] = useState([]);
  const [childCategories, setChildCategories] = useState([]);
  const [parentCategoryFilter, setParentCategoryFilter] = useURLState(
    [],
    "parentCategory"
  );
  const [childCategoryFilter, setChildCategoryFilter] = useURLState(
    [],
    "subCategory"
  );
  const [minDurationFilter, setMinDurationFilter] = useURLState(
    "",
    "minDuration"
  );
  const [maxDurationFilter, setMaxDurationFilter] = useURLState(
    "",
    "maxDuration"
  );
  const location = useLocation();
  const navigate = useNavigate();
  const autoCompleteFilter = createFilterOptions();
  const [sortOrder, setSortOrder] = useURLState("", "sorting");
  const [maxDurationOfCalls, setMaxDurationOfCalls] = useState("");
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [filtersInitialized, setFiltersInitialized] = useState(false);

  const [appliedFilters, setAppliedFilters] = useState({
    feedback1Filter: [],
    feedback2Filter: [],
    parentCategoryFilter: [],
    childCategoryFilter: [],
    statusFilter: [],
    minDurationFilter: "",
    maxDurationFilter: "",
  });

  const callDetailsFields = [
    {
      value: "callId",
      label: "Call ID",
    },
    {
      value: "phoneNumber",
      label: "Phone Number",
    },
    {
      value: "callType",
      label: "Call Type",
    },
    {
      value: "parentCategory",
      label: "Category",
    },
    {
      value: "subCategory",
      label: "Sub Category",
    },
    {
      value: "callDuration",
      label: "Duration",
    },
    // {
    //   value: "transcript",
    //   label: "Transcript",
    // },
    // {
    //   value: "audioRecording",
    //   label: "Audio Recording",
    // },
    // {
    //   value: "bot",
    //   label: "Bot",
    // },
    {
      value: "status",
      label: "Audit Status",
    },
    // {
    //   value: "intentIdentified",
    //   label: "Intent Identified",
    // },
    // {
    //   value: "agentFeedback",
    //   label: "Agent Feedback",
    // },
    {
      value: "feedback1",
      label: "Feedback 1",
    },
    {
      value: "feedback2",
      label: "Feedback 2",
    },
  ];

  const columns = [
    {
      field: "callId",
      header: "Call ID",
      render: (row) => {
        return row.voiceCallId;
      },
    },
    {
      field: "phoneNumber",
      header: "Phone Number",
    },
    {
      field: "timestamp",
      header: "Date & Time",
      render: (row) => {
        const date = new Date(row.timestamp);
        const day = date.toLocaleString("en-US", { day: "2-digit" });
        const month = date.toLocaleString("en-US", { month: "short" });
        const time = date.toLocaleString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
        });
        return `${day} ${month}, ${time}`;
      },
      // style: { width: "10%" },
    },
    {
      field: "callType",
      header: "Call Type",
      render: (rowInfo) => {
        if (rowInfo?.callType === "agentHandOff") {
          return "Agent Handoff";
        } else if (rowInfo?.callType === "blank") {
          return "Blank Call";
        } else if (rowInfo?.callType === "virtualAgent") {
          return "Virtual Agent";
        }
      },
    },
    {
      field: "feedback1",
      header: (
        <Tooltip title={"Is there an intent match?"} placement="top">
          <Box display="flex" alignItems={"center"} columnGap={1}>
            <Typography variant="caption">Feedback 1</Typography>
            <Info width={16} height={16} />
          </Box>
        </Tooltip>
      ),
      render: (rowInfo) => {
        return (
          <Box display="flex" alignItems="center" columnGap={2}>
            <Typography variant="body2" verticalAlign="start">
              {rowInfo.isIntentMatchedUserResponse === true
                ? "Satisfied"
                : rowInfo.isIntentMatchedUserResponse === false
                ? "Dissatisfied"
                : "Unrated"}
            </Typography>
            {rowInfo.isIntentMatchedUserResponse}
            {rowInfo.isIntentMatchedUserResponse === true && (
              <ThumbUpGreenFilled width={16} height={16} />
            )}
            {rowInfo.isIntentMatchedUserResponse === false && (
              <ThumbDownRedFilled width={16} height={16} />
            )}
          </Box>
        );
      },
    },
    {
      field: "feedback2",
      header: (
        <Tooltip title={"Is the answer correct?"} placement="top">
          <Box display="flex" alignItems={"center"} columnGap={1}>
            <Typography variant="caption">Feedback 2</Typography>
            <Info width={16} height={16} />
          </Box>
        </Tooltip>
      ),
      render: (rowInfo) => {
        return (
          <Box display="flex" alignItems="center" columnGap={2}>
            <Typography variant="body2">
              {rowInfo.isAnswerCorrect === true
                ? "Satisfied"
                : rowInfo.isAnswerCorrect === false
                ? "Dissatisfied"
                : "Unrated"}
            </Typography>
            {rowInfo.isAnswerCorrect === true && (
              <ThumbUpGreenFilled width={16} height={16} />
            )}
            {rowInfo.isAnswerCorrect === false && (
              <ThumbDownRedFilled width={16} height={16} />
            )}
          </Box>
        );
      },
    },
    {
      field: "category",
      header: "Category",
      render: (rowInfo) => {
        return rowInfo?.parentCategoryName;
      },
    },
    {
      field: "subCategory",
      header: "Sub Category",
      render: (rowInfo) => {
        return rowInfo?.subCategoryName;
      },
    },

    {
      field: "duration",
      header: "Duration (in seconds)",
      render: (rowInfo) => {
        return `${Math.ceil(isNaN(rowInfo?.duration) ? 0 : rowInfo?.duration)}`;
      },
    },
    {
      field: "status",
      header: "Status",
      render: (rowInfo) => {
        if (rowInfo?.auditedCallTypeSub) {
          return rowInfo?.auditedCallTypeSub;
        } else if (rowInfo?.auditedCallType) {
          return rowInfo?.auditedCallType;
        } else {
          return "Pending";
        }
      },
    },
  ];

  const parseFiltersFromURL = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    const filters = {
      feedback1Filter: [],
      feedback2Filter: [],
      parentCategoryFilter: [],
      childCategoryFilter: [],
      statusFilter: [],
      minDurationFilter: "",
      maxDurationFilter: "",
    };

    searchParams.forEach((value, key) => {
      if (value) {
        switch (key) {
          case "feedback1":
            filters.feedback1Filter = value.split(",").filter(Boolean);
            break;
          case "feedback2":
            filters.feedback2Filter = value.split(",").filter(Boolean);
            break;
          case "parentCategory":
            filters.parentCategoryFilter = value.split(",").filter(Boolean);
            break;
          case "childCategory":
            filters.childCategoryFilter = value.split(",").filter(Boolean);
            break;
          case "status":
            filters.statusFilter = value.split(",").filter(Boolean);
            break;
          case "minDuration":
            filters.minDurationFilter = value;
            break;
          case "maxDuration":
            filters.maxDurationFilter = value;
            break;
        }
      }
    });

    return filters;
  }, [location.search]);

  const getCallsCount = async () => {
    try {
      setLoading(true);
      const urlParams = new URLSearchParams();
      /**
       * isOnPhoneCall
       * For development build we will show all calls made from playground
       * and for production build we will show all calls made from actual phone number
       */
      urlParams.set("isOnPhoneCall", buildMode === "production" ? true : null);
      if (headerDate?.startDate) {
        const modifiedStartDate = headerDate.startDate;
        const modifiedEndDate = headerDate.endDate
          .clone()
          .add(1, "day")
          .startOf("day");
        urlParams.set("startDate", modifiedStartDate.toISOString());
        urlParams.set("endDate", modifiedEndDate.toISOString());
      }
      const response = await getAgentCallsCountOverviewApi(
        urlParams.toString()
      );
      setCallsCountInfo(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const getCallData = async () => {
    setLoading(true);
    try {
      const urlParams = new URLSearchParams();
      urlParams.set("page", currentPage.toString());

      // Handle parentCategory filter
      if (appliedFilters.parentCategoryFilter.length > 0) {
        urlParams.set(
          "parentCategory",
          appliedFilters.parentCategoryFilter.join(",")
        );
      }

      // Handle subCategory filter
      if (appliedFilters.childCategoryFilter.length > 0) {
        urlParams.set(
          "subCategory",
          appliedFilters.childCategoryFilter.join(",")
        );
      }

      // Handle other filters
      if (appliedFilters.feedback1Filter.length > 0) {
        urlParams.set("feedback1", appliedFilters.feedback1Filter.join(","));
      }
      if (appliedFilters.feedback2Filter.length > 0) {
        urlParams.set("feedback2", appliedFilters.feedback2Filter.join(","));
      }
      if (appliedFilters?.statusFilter?.length > 0) {
        urlParams.set("status", appliedFilters.statusFilter.join(","));
      }
      if (appliedFilters.minDurationFilter !== "") {
        urlParams.set(
          "minDuration",
          appliedFilters.minDurationFilter.toString()
        );
      }
      if (appliedFilters.maxDurationFilter !== 0) {
        urlParams.set(
          "maxDuration",
          appliedFilters.maxDurationFilter.toString()
        );
      }

      urlParams.set("search", headerSearch);
      urlParams.set("minDuration", appliedFilters.minDurationFilter.toString());
      urlParams.set("maxDuration", appliedFilters.maxDurationFilter.toString());
      urlParams.set("sorting", sortOrder);
      urlParams.set(
        "isOnPhoneCall",
        buildMode === "production" ? "true" : "null"
      );

      if (selectedMarkedByID) urlParams.set("markedBy", selectedMarkedByID);
      if (callTypeTab !== "All") urlParams.set("callType", callTypeTab);

      if (headerDate?.startDate) {
        const modifiedStartDate = headerDate.startDate;
        const modifiedEndDate = headerDate.endDate
          .clone()
          .add(1, "day")
          .startOf("day");
        urlParams.set("startDate", modifiedStartDate.toISOString());
        urlParams.set("endDate", modifiedEndDate.toISOString());
      }
      const response = await getCalls(urlParams.toString());
      if (response?.calls) {
        setLoading(false);
        updateTableData(response?.calls);
        setTotalCallsCount(response?.totalCallsCount);
        setValidCallsCount(response?.validCount);
        setBlankCallsCount(response?.blankCallCount);
        setJunkCallsCount(response?.junkCount);
        setPaginationCount(() => {
          if (callTypeTab === "All") {
            return Math.ceil(response?.totalCallsCount / 10);
          } else if (callTypeTab === "Valid") {
            return Math.ceil(response?.validCount / 10);
          } else if (callTypeTab === "Blank") {
            return Math.ceil(response?.blankCallCount / 10);
          } else if (callTypeTab === "Junk") {
            return Math.ceil(response?.junkCount / 10);
          }
        });
        setMaxDurationOfCalls(Math.ceil(response?.maxDuration));
      }
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
      console.log(error);
    }
  };

  const getMarkedByDropdown = async () => {
    try {
      const response = await getMarkedByDropdownAPI();
      if (response) {
        setMarkedByDropdownData(response);
      }
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const updateTableData = (callsData) => {
    const newData = callsData?.map((item, index) => {
      return {
        id: index,
        // this is the index we've created to track the row from callsData for onClick
        _id: item._id,
        // this is the id from the backend
        callId: item.callId,
        question: item.startLine,
        answer: item.endLine,
        agentEmail: item.agent ? item.agent.email : "N/A",
        agentName: item?.agent
          ? item.agent?.firstName + item.agent?.lastName
          : "N/A",
        audio: item.gcsUri,
        timestamp: item.startTime,
        callType: item.callType,
        reaction: item?.endLineReaction,
        reasonForDislike: item?.thumbsDownReason,
        logs: item?.logs,
        thumbsDownReason: item?.thumbsDownReason,
        weaviateQuestionPicked: item?.weaviateQuestionPicked,
        totalResponseTime: item?.totalResponseTimeInMilliSeconds,
        phoneNumber: item?.phoneNumber,
        vectorDbLog: item?.logs?.find((log) => log.processId === 5),
        gptLog: item?.logs?.find((log) => log.processId === 4),
        audited: item?.audited,
        auditUserObjects: item?.auditUserObjects,
        auditedCallType: item?.auditedCallType,
        auditedCallTypeSub: item?.auditedCallTypeSub,
        auditTime: item?.auditTime,
        voiceCallId: item?.voiceCallId,
        intentMatchedUserResponseExplanation:
          item?.intentMatchedUserResponseExplanation,
        isIntentMatchedUserResponse: item?.isIntentMatchedUserResponse,
        isAnswerCorrect: item?.isAnswerCorrect,
        intentCategory: item?.intentCategory,
        status: item?.status,
        answerCorrectnessExplanation: item?.answerCorrectnessExplanation,
        parentCategoryName: item?.callSessionData?.parentCategoryName,
        subCategoryName: item?.callSessionData?.subCategoryName,
        duration: item?.audioCallDurationInSeconds,
      };
    });
    setCallsData(newData);
  };

  const handleTableOnClick = (event) => {
    const row = event.target.closest("tr[id]");
    if (row) {
      const singleCallDetails = callsData.find((call) => call._id === row.id);

      navigate(
        `/calls/${singleCallDetails._id}?voiceCallId=${singleCallDetails?.voiceCallId}`
      );

      setHeaderDate({ ...headerDate, changed: "default" });

      // setModal(true);
      // setCurrentCall(singleCallDetails);
    }
  };

  const handleSelectFeedback = (e, feedback) => {
    setCurrentPage(1);

    const selectedFileType = e.target.value;
    if (feedback === "feedback1") {
      const newFeedbackFilter = [...feedback1Filter];
      const index = feedback1Filter.indexOf(selectedFileType);
      if (index > -1) {
        newFeedbackFilter.splice(index, 1);
      } else {
        newFeedbackFilter.push(selectedFileType);
      }
      setFeedback1Filter(newFeedbackFilter);
    } else {
      const newFeedbackFilter = [...feedback2Filter];
      const index = feedback2Filter.indexOf(selectedFileType);
      if (index > -1) {
        newFeedbackFilter.splice(index, 1);
      } else {
        newFeedbackFilter.push(selectedFileType);
      }
      setFeedback2Filter(newFeedbackFilter);
    }
  };

  const handleTabChange = (event, newValue) => {
    setCurrentPage(1);
    setCallTypeTab(newValue);
  };

  // const handleFilter = (e, filter, setFilter) => {
  //   const selectedFilter = e.target.value;
  //   let newFilter;
  //   if (Array.isArray(filter)) {
  //     newFilter = [...filter];
  //     const index = filter.indexOf(selectedFilter);
  //     if (index > -1) {
  //       newFilter.splice(index, 1);
  //     } else {
  //       newFilter.push(selectedFilter);
  //     }
  //   } else {
  //     newFilter = selectedFilter === filter ? "" : selectedFilter;
  //   }
  //   setFilter(newFilter);
  //   setCurrentPage(1);
  // };

  const handlePageOnChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (headerDate.changed === "manual") {
      setHeaderDate({ ...headerDate, changed: "default" });
    }
  };

  // const updateChildCategory = (event, _parentCategories) => {
  //   let allChildCategories = [];
  //   const parentCategoryName = event?.target?.value;
  //   const selectedParentCategory = [...parentCategoryFilter];
  //   let childCategoriesToRemove = [];
  //   const newParentCategoryIndex =
  //     selectedParentCategory.indexOf(parentCategoryName);
  //   if (parentCategoryName) {
  //     if (newParentCategoryIndex > -1) {
  //       childCategoriesToRemove = _parentCategories.find(
  //         (pc) => pc.name === parentCategoryName
  //       )?.categories;
  //       selectedParentCategory.splice(newParentCategoryIndex, 1);
  //     } else {
  //       selectedParentCategory.push(parentCategoryName);
  //     }
  //   }

  //   if (selectedParentCategory.length > 0) {
  //     _parentCategories.forEach((pc) => {
  //       if (selectedParentCategory.includes(pc.name)) {
  //         allChildCategories = [...allChildCategories, ...pc.categories];
  //       }
  //     });
  //   } else {
  //     _parentCategories.forEach((pc) => {
  //       allChildCategories = [...allChildCategories, ...pc.categories];
  //     });
  //   }
  //   setChildCategories(allChildCategories);

  //   // Remove the child categories if the parent category is removed from filter
  //   if (childCategoriesToRemove.length > 0) {
  //     const newChildCategory = [...childCategoryFilter];
  //     childCategoriesToRemove.forEach((cc) => {
  //       const ccIndex = newChildCategory.indexOf(cc.name);
  //       if (ccIndex > -1) {
  //         newChildCategory.splice(ccIndex, 1);
  //       }
  //     });
  //     setChildCategoryFilter(newChildCategory);
  //   }
  // };

  const getParentCategories = async () => {
    try {
      const allParentCategories = await getParentCategoriesApi();
      setParentCategories(allParentCategories);
      // updateChildCategory({}, allParentCategories);
      let allChildCategories = [];
      if (parentCategoryFilter.length > 0) {
        allParentCategories.forEach((pc) => {
          if (parentCategoryFilter.includes(pc.name)) {
            allChildCategories = [...allChildCategories, ...pc.categories];
          }
        });
      } else {
        allParentCategories.forEach((pc) => {
          allChildCategories = [...allChildCategories, ...pc.categories];
        });
      }
      setChildCategories(allChildCategories);
    } catch (error) {
      const errorMessage = error?.response?.data
        ? error.response.data
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const isFilterApplied = () => {
    return Object.entries(appliedFilters).some(([key, value]) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      if (key === "minDurationFilter") {
        return value !== "";
      }
      if (key === "maxDurationFilter") {
        return value !== 0 && value !== "";
      }
      return value !== "" && value !== null && value !== undefined;
    });
  };

  const applyFilters = (newFilters) => {
    const updatedFilters = {
      ...newFilters,
      parentCategoryFilter: newFilters.parentCategoryFilter
        .map((item) => (typeof item === "string" ? item : item.name))
        .filter(Boolean),
      childCategoryFilter: newFilters.childCategoryFilter
        .map((item) => (typeof item === "string" ? item : item.name))
        .filter(Boolean),
    };
    setAppliedFilters(updatedFilters);
    setCurrentPage(1);

    const searchParams = new URLSearchParams();
    Object.entries(updatedFilters).forEach(([key, value]) => {
      if (Array.isArray(value) && value.length > 0) {
        const paramKey = key.replace("Filter", "");
        searchParams.set(paramKey, value.join(","));
      } else if (value !== "" && value !== null && value !== undefined) {
        const paramKey = key.replace("Filter", "");
        searchParams.set(paramKey, value.toString());
      }
    });

    const currentSearchParams = new URLSearchParams(window.location.search);
    if (currentSearchParams.has("startDate")) {
      searchParams.set("startDate", currentSearchParams.get("startDate"));
    }
    if (currentSearchParams.has("endDate")) {
      searchParams.set("endDate", currentSearchParams.get("endDate"));
    }
    if (currentSearchParams.has("page")) {
      searchParams.set("page", currentSearchParams.get("page"));
    }

    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  const handleRemoveFilter = (filterType, filterValue) => {
    if (
      filterType === "minDurationFilter" ||
      filterType === "maxDurationFilter"
    ) {
      setAppliedFilters((prevFilters) => ({
        ...prevFilters,
        minDurationFilter: "",
        maxDurationFilter: "",
      }));

      const searchParams = new URLSearchParams(location.search);
      searchParams.delete("minDuration");
      searchParams.delete("maxDuration");
      navigate(`?${searchParams.toString()}`, { replace: true });
    } else {
      let newFilter;
      if (Array.isArray(appliedFilters[filterType])) {
        if (Array.isArray(filterValue)) {
          newFilter = appliedFilters[filterType].filter(
            (item) => !filterValue.includes(item)
          );
        } else {
          newFilter = appliedFilters[filterType].filter(
            (item) => item !== filterValue
          );
        }
      } else {
        newFilter = "";
      }
      setAppliedFilters((prevFilters) => ({
        ...prevFilters,
        [filterType]: newFilter,
      }));

      const searchParams = new URLSearchParams(location.search);
      const paramKey = filterType.replace("Filter", "");

      if (newFilter.length > 0) {
        searchParams.set(paramKey, newFilter.join(","));
      } else {
        searchParams.delete(paramKey);
      }

      navigate(`?${searchParams.toString()}`, { replace: false });
    }
  };

  const clearAllFilters = () => {
    setAppliedFilters({
      feedback1Filter: [],
      feedback2Filter: [],
      parentCategoryFilter: [],
      childCategoryFilter: [],
      statusFilter: [],
      minDurationFilter: "",
      maxDurationFilter: "",
    });

    const currentSearchParams = new URLSearchParams(location.search);
    currentSearchParams.delete("feedback1");
    currentSearchParams.delete("feedback2");
    currentSearchParams.delete("parentCategory");
    currentSearchParams.delete("childCategory");
    currentSearchParams.delete("status");
    currentSearchParams.delete("minDuration");
    currentSearchParams.delete("maxDuration");
    navigate(`?${currentSearchParams.toString()}`, { replace: true });
  };

  useEffect(() => {
    getParentCategories();
  }, []);

  useEffect(() => {
    getCallsCount();
    getMarkedByDropdown();
  }, [headerDate.startDate, headerDate.endDate]);

  useEffect(() => {
    const filtersFromURL = parseFiltersFromURL();
    setAppliedFilters(filtersFromURL);
    setFiltersInitialized(true);
  }, [parseFiltersFromURL]);

  useEffect(() => {
    if (filtersInitialized) {
      getCallData();
      getCallsCount();
    }
  }, [
    filtersInitialized,
    currentPage,
    callTypeTab,
    headerDate.startDate,
    headerDate.endDate,
    selectedMarkedByID,
    sortOrder,
    appliedFilters,
  ]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    if (headerSearch && paginationCount) {
      if (currentPage === 1) {
        getCallData();
      } else {
        setCurrentPage(1);
      }
    }

    if (
      urlSearchParams.has("search") &&
      urlSearchParams.get("search") === "" &&
      paginationCount !== undefined // checked for pagination count because it should not work on first load, once search then only it should
    ) {
      getCallData();
    }

    if (headerDate.changed === "manual") {
      setCurrentPage(1);
    }
  }, [headerSearch, headerDate.changed]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        padding: "16px 48px",
      }}
    >
      <FlexBox>
        <FlexBox className={styles.stats} sx={{ flex: 1 }} columnGap={0}>
          <Box className={styles.box}>
            <Typography variant="body1">Total Calls</Typography>
            <Typography variant="h5" fontWeight={500}>
              {callsCountInfo?.totalCallsCount || 0}
            </Typography>
          </Box>

          <Box className={styles.box}>
            <Typography variant="body1">Valid Calls</Typography>
            <Typography variant="h5" fontWeight={500}>
              {callsCountInfo?.totalValidCallsCount || 0}
            </Typography>
          </Box>
          <Box className={styles.box}>
            <Typography variant="body1">Blank Calls</Typography>
            <Typography variant="h5" fontWeight={500}>
              {callsCountInfo?.totalBlankCallsCount || 0}
            </Typography>
          </Box>
        </FlexBox>
        <FlexBox className={styles.stats} columnGap={0} minWidth={"25%"}>
          <Box className={styles.box}>
            <Typography variant="body1">Total Minutes</Typography>
            <Typography variant="h5" fontWeight={500}>
              {getFormattedTime(callsCountInfo?.totalBillDurationInSeconds) ||
                0}
            </Typography>
          </Box>
        </FlexBox>
      </FlexBox>
      <Box className={styles.container}>
        <Box className={styles.tabContainer}>
          <Tabs
            value={callTypeTab}
            onChange={handleTabChange}
            aria-label="call tabs"
            className={styles.tabs}
            TabIndicatorProps={{ style: { background: "#0f0f0f" } }}
            sx={{
              width: "50%",
              "& span.MuiTabs-indicator": {
                borderRadius: "10px 10px 0 0",
                height: 3,
              },
            }}
          >
            <Tab
              label={
                <Typography
                  fontFamily="DM Sans"
                  variant="body2"
                  fontWeight={500}
                >
                  All calls {totalCallsCount}
                </Typography>
              }
              value="All"
            />
            <Tab
              label={
                <Typography
                  fontFamily="DM Sans"
                  variant="body2"
                  fontWeight={500}
                >
                  Valid {validCallsCount}
                </Typography>
              }
              value="Valid"
            />
            <Tab
              label={
                <Typography
                  fontFamily="DM Sans"
                  variant="body2"
                  fontWeight={500}
                >
                  Blank {blankCallsCount}
                </Typography>
              }
              value="Blank"
            />
          </Tabs>

          <Button
            startIcon={<RightArrow color="white" />}
            variant="dark"
            sx={{ borderRadius: "12px", padding: "12px 24px", mt: 4 }}
            onClick={() => setExportModal(true)}
          >
            Export Data
          </Button>

          <ExportModal
            open={exportModal}
            onClose={() => setExportModal(false)}
            callId={callsData[0]?._id}
            totalCalls={totalCallsCount}
            fields={callDetailsFields}
            startDate={headerDate.startDate}
            endDate={headerDate.endDate}
            filters={appliedFilters}
          />
        </Box>

        <FlexBox mt={4} mb={2}>
          <Button
            color="inherit"
            variant="contained"
            startIcon={<IconFilter2 width={20} height={20} />}
            sx={{ backgroundColor: "#f9f9f9", minWidth: 0 }}
            onClick={() => setFilterModalOpen(true)}
          >
            Filter
          </Button>

          {isFilterApplied() && (
            <Tooltip title="Clear all filters">
              <Button
                variant="text"
                color="primary"
                sx={{ minWidth: 0 }}
                onClick={clearAllFilters}
              >
                Clear All
              </Button>
            </Tooltip>
          )}

          {Object.entries(appliedFilters).map(([filterType, filterValues]) => {
            if (
              filterType === "minDurationFilter" ||
              filterType === "maxDurationFilter"
            ) {
              return null;
            }
            if (Array.isArray(filterValues)) {
              if (filterValues.length > 0) {
                // return filterValues.map((filterValue) => (
                const displayName =
                  filterType === "parentCategoryFilter"
                    ? "Category"
                    : filterType === "childCategoryFilter"
                    ? "Subcategory"
                    : `${filterType.charAt(0).toUpperCase()}${filterType
                        .replace("Filter", "")
                        .slice(1)}`;
                return (
                  <FlexBox key={filterType} className={styles.chip}>
                    <p>
                      {displayName}: {filterValues.join(", ")}
                    </p>
                    <IconButton
                      size="small"
                      sx={{ padding: 1 }}
                      onClick={() => {
                        handleRemoveFilter(filterType, filterValues);
                      }}
                    >
                      <X width={12} height={12} />
                    </IconButton>
                  </FlexBox>
                );
                // ));
              }
            } else if (filterValues) {
              return (
                <FlexBox key={filterValues} className={styles.chip}>
                  <p>
                    {filterType}: {filterValues}
                  </p>
                  <IconButton
                    sx={{ padding: 1 }}
                    size="small"
                    onClick={() => handleRemoveFilter(filterType, filterValues)}
                  >
                    <X width={12} height={12} />
                  </IconButton>
                </FlexBox>
              );
            }
            return null;
          })}

          {(appliedFilters.minDurationFilter ||
            appliedFilters.maxDurationFilter) && (
            <FlexBox className={styles.chip}>
              <p>
                Duration:
                {appliedFilters.minDurationFilter &&
                  ` ${appliedFilters.minDurationFilter}s`}
                {appliedFilters.minDurationFilter &&
                  appliedFilters.maxDurationFilter &&
                  " - "}
                {appliedFilters.maxDurationFilter &&
                  `${appliedFilters.maxDurationFilter}s`}
              </p>
              <IconButton
                sx={{ padding: 1 }}
                size="small"
                onClick={() => handleRemoveFilter("minDurationFilter", "")}
              >
                <X width={12} height={12} />
              </IconButton>
            </FlexBox>
          )}

          <FilterModal
            open={filterModalOpen}
            onClose={() => setFilterModalOpen(false)}
            filters={appliedFilters}
            setFilters={setAppliedFilters}
            parentCategories={parentCategories}
            childCategories={childCategories}
            statusFilterList={statusFilterList}
            maxDurationOfCalls={maxDurationOfCalls}
            applyFilters={applyFilters}
          />

          <div
            style={{
              marginLeft: "auto",
            }}
          >
            <FundamentoPopup
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              className={styles.popup}
              triggeringComponent={
                <Button
                  variant="text"
                  color="inherit"
                  endIcon={<SortAscending />}
                  sx={{
                    padding: 3,
                    minWidth: 0,
                    width: "fit-content",
                  }}
                >
                  Sort
                </Button>
              }
            >
              <Box>
                <Box
                  className={styles.popupItem}
                  onClick={() => setSortOrder("Duration-ASC")}
                >
                  <Typography variant="body2">Duration (min to max)</Typography>
                </Box>
                <Box
                  className={styles.popupItem}
                  onClick={() => setSortOrder("Duration-DESC")}
                >
                  <Typography variant="body2">Duration (max to min)</Typography>
                </Box>
                <Box
                  className={styles.popupItem}
                  onClick={() => setSortOrder("Date-ASC")}
                >
                  <Typography variant="body2">Date - Ascending</Typography>
                </Box>
                <Box
                  className={styles.popupItem}
                  onClick={() => setSortOrder("Date-DESC")}
                >
                  <Typography variant="body2">Date - Descending</Typography>
                </Box>
              </Box>
            </FundamentoPopup>
          </div>
        </FlexBox>

        {loading && callsData.length === 0 && (
          <Box className={styles.emptyContainer}>
            <Loader width={40} height={40} color={theme.palette.grey[400]} />
          </Box>
        )}

        {!loading && callsData.length > 0 && (
          <Box
            sx={{
              "& th": {
                backgroundColor: `${theme.palette.grey[50]} !important`,
              },
            }}
          >
            <FundamentoTable
              columns={columns}
              data={callsData}
              passRowToRender={true}
              pagination={true}
              currentPage={currentPage}
              onPageChange={handlePageOnChange}
              totalPageCount={paginationCount}
              tableOnClick={handleTableOnClick}
              className={styles.table}
              rowIdKey="_id"
            />
          </Box>
        )}

        {!loading &&
          callsData.length === 0 &&
          paginationCount !== undefined && (
            <EmptyState title="No calls found" />
          )}
      </Box>
    </Box>
  );
};

export default Audit;
